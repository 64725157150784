import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { mcatLandingPath } from 'my-core/routes';

import { Container } from 'my-layout';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import heroImg620 from 'images/mcat/stock-med-student-620.jpg';
import heroImg from 'images/mcat/stock-med-student.jpg';

const classes = {
  section: ({ breakpoints, palette, spacing }) => ({
    padding: spacing(5, 0),
    background: palette.purple.background,

    [breakpoints.up('sm')]: {
      padding: spacing(11, 0),
    },
  }),
  container: ({ breakpoints, spacing }) => ({
    [breakpoints.down('lg')]: {
      maxWidth: 932,
    },
    [breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      gap: spacing(8),
    },
  }),

  content: ({ breakpoints }) => ({
    [breakpoints.up('lg')]: { flex: '0 0 500px' },
  }),
  header: ({ breakpoints, spacing }) => ({
    marginTop: spacing(1),
    marginBottom: spacing(2),

    [breakpoints.up('sm')]: {
      marginBottom: spacing(4),
    },
  }),
  contentInternal: ({ breakpoints }) => ({
    [breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }),
  body: ({ breakpoints, spacing }) => ({
    margin: spacing(4, 0),

    [breakpoints.up('sm')]: { marginTop: 0 },
  }),

  statsWrapper: ({ breakpoints, palette, spacing }) => ({
    [breakpoints.only('xs')]: {
      order: -1,
      width: '100%',
      color: palette.common.white,
      padding: spacing(5, 2),
      backgroundImage: `
      linear-gradient(to right, ${palette.grey[900]} 41.09%, ${alpha(palette.grey[900], 0)} 100%),
      url(${heroImg620}) 
      `,
      backgroundSize: 'cover',
      borderRadius: 16,
    },
  }),
  stats: ({ breakpoints, palette, spacing }) => ({
    display: 'flex',
    gap: spacing(2),

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: spacing(1),
      maxWidth: 162,
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [breakpoints.up('sm')]: {
      marginBottom: spacing(4),
      gap: 34,

      '& > div': {
        position: 'relative',
        width: 96,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: spacing(0.5),
      },
      '& > div + div::before': {
        content: "''",
        position: 'absolute',
        left: -17,
        top: 0,
        height: '100%',
        width: 1,
        backgroundColor: palette.text.primary,
      },
    },
  }),

  imageWrapper: ({ breakpoints }) => ({
    '& > img': {
      width: '100%',
      borderRadius: 16,
    },

    [breakpoints.down('lg')]: {
      display: 'none',
      visiblity: 'hidden',
    },
  }),
  imageWrapperTablet: ({ breakpoints, spacing }) => ({
    width: '40%',

    '& > img': {
      width: '100%',
      borderRadius: 16,
    },

    [breakpoints.only('xs')]: {
      display: 'none',
      visiblity: 'hidden',
    },
    [breakpoints.only('sm')]: {
      float: 'right',
      marginLeft: spacing(2),
      marginBottom: spacing(2),
    },
    [breakpoints.only('md')]: {
      float: 'left',
      marginRight: spacing(4),
      marginBottom: spacing(4),
    },
    [breakpoints.up('lg')]: {
      display: 'none',
      visiblity: 'hidden',
    },
  }),
};

export default function LandingPageMCAT() {
  return (
    <section css={classes.section}>
      <Container css={classes.container}>
        <div css={classes.content}>
          <Typography color="purple.main" component="span" typography={{ xs: 'subtitle1', sm: 'h6' }}>
            MCAT by Wizeprep
          </Typography>
          <Typography component="h2" css={classes.header} typography={{ xs: 'h5', sm: 'h3' }}>
            The Revolution in MCAT Prep Is Here!
          </Typography>
          <div css={classes.contentInternal}>
            <div css={classes.imageWrapperTablet}>
              <img src={heroImg} />
            </div>
            <Typography css={classes.body} typography={{ xs: 'body1', sm: 'body3' }}>
              The Wizeprep MCAT Elite 515 course is the only program where you pay based on your performance -
              guaranteed!
            </Typography>
            <div css={classes.statsWrapper}>
              <div css={classes.stats}>
                {STATS.map(({ bottom, id, top }) => (
                  <div key={id}>
                    <Typography component="span" variant="h5">
                      {top}
                    </Typography>
                    <Typography component="span" variant="overline">
                      {bottom}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <Button component={Link} size="large" to={mcatLandingPath()} variant="contained">
              Learn More
            </Button>
          </div>
        </div>
        <div css={classes.imageWrapper}>
          <img src={heroImg} />
        </div>
      </Container>
    </section>
  );
}

const STATS = [
  { id: 'guarantee', top: '515+', bottom: 'performance guaranteed' },
  { id: 'hours-comp', top: '144', bottom: 'hours live instruction' },
  { id: 'schedules', top: '7\u00A0Day', bottom: 'risk-free guarantee' },
];
