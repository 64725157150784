import { useTheme } from '@mui/material/styles';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { useCallback } from 'react';

gsap.registerPlugin(ScrollToPlugin);

export default function useScrollTo() {
  const { APPBAR_OFFSET: offsetY } = useTheme().constants;
  const scrollTo = useCallback(
    (node, { autoKill, duration: durationOpt, extraOffset = 20, ...rest } = {}) => {
      if (!node) return;
      const scrollToTweenProps = { scrollTo: { y: node, offsetY: offsetY + extraOffset, autoKill }, ...rest };

      if (durationOpt === 0) {
        gsap.set(window, { ...scrollToTweenProps });
        return;
      }

      gsap.to(window, { duration: durationOpt || 1, ease: 'power1.inOut', ...scrollToTweenProps });
    },
    [offsetY],
  );

  return scrollTo;
}
